import React, { useState } from 'react';
import AboutUsTeamSwiper from '../../templates/aboutUsTeamSwiper';
import SendMessageModal from '../send-message-modal';
import ApplyNowModal from '../applyNowModal';
import { GatsbyImage } from 'gatsby-plugin-image';

// This file is used at : [ blog.js | careers.js ]

const OurStaffHomeComponent = props => {
  const { ourStaffHomeTitle, ourStaffHomeDescription, ourStaffHomeImage } =
    props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [applyNowModal, setApplyNowModal] = useState(false);
  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <SendMessageModal onClose={handleModalClose} isOpen={modalIsOpen} />
      <div
        className=''
        style={{
          marginBottom: '100px',
        }}
      >
        <div className='aboutus-home-section-container full-container'>
          <div className='row align-items-center'>
            <div className='col-xl-6 order-2 order-xl-1'>
              <div className='aboutus-home-section-container-text'>
                <p
                  className='ourStaff-home-section-container-text-title'
                  dangerouslySetInnerHTML={{ __html: ourStaffHomeTitle }}
                />
                <p
                  className='ourStaff-home-section-container-text-description'
                  dangerouslySetInnerHTML={{ __html: ourStaffHomeDescription }}
                />
                <button
                  // to={'/apply'}
                  onClick={() => setApplyNowModal(true)}
                  className='career-position-section-swiper-item-button '
                >
                  Apply Now
                </button>
              </div>
            </div>
            <div className='aboutus-home-section-container-image order-1 order-xl-2 col-xl-6 d-flex justify-content-xl-start justify-content-center justify-content-xxl-center'>
              <div className='image-shadow' />
              <div className='gradient-1' />
              <div className='gradient-2' />
              <GatsbyImage
                image={ourStaffHomeImage}
                className='aboutus-home-section-container-image-img'
                alt='About us image'
              />
            </div>
          </div>
        </div>
      </div>
      <AboutUsTeamSwiper color={'white'} />
      <ApplyNowModal
        isOpen={applyNowModal}
        onClose={() => setApplyNowModal(false)}
      />
    </>
  );
};
export default OurStaffHomeComponent;
