import React, { useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'gatsby';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// This file is used at : [ careers.js ]

SwiperCore.use([Autoplay]);
const CareerPosition = props => {
  const {
    // careerPositionSubtitle,
    // careerPositionTitle,
    careerPositionFrontEnd,
    careerPositionText,
    careerPositionLocation,
    careerPositionBackEnd,
    careerPositionDevOps,
    careerPositionUXDesigner,
    careerPositionQA,
    careerPositionProjectManagement,
    careerPositionProjectManager,
    careerPositionProjectSeniorCostumerSupport,
    careerPositionSeniorContentManager,
    careerPositionProjectSocialMediaMarketingSpecialist,
    careerPositionMarketingManager,
    careerPositionProjectSeniorTechnicalWriter,
    careerDescriptionFrontend,
    careerDescriptionBackend,
    careerDescriptionContentManager,
    careerDescriptionCostumerSupport,
    careerDescriptionDevops,
    careerDescriptionMarketingManager,
    careerDescriptionProjectManagement,
    careerDescriptionProjectManager,
    careerDescriptionQa,
    careerDescriptionSocialMediaMarketingSpecialist,
    careerDescriptionTechnicalWriter,
    careerDescriptionUxdesigner,
  } = props;

  const [careerPosition, setCareerPosition] = useState([
    {
      title: careerPositionFrontEnd,
      text: careerPositionText,
      description: careerDescriptionFrontend,
      location: careerPositionLocation,
      path: '/senior-frontend',
    },
    {
      title: careerPositionBackEnd,
      text: careerPositionText,
      description: careerDescriptionBackend,
      location: careerPositionLocation,
      path: '/senior-back-end',
    },
    {
      title: careerPositionDevOps,
      text: careerPositionText,
      description: careerDescriptionDevops,
      location: careerPositionLocation,
      path: '/devops',
    },
    {
      title: careerPositionUXDesigner,
      text: careerPositionText,
      description: careerDescriptionUxdesigner,
      location: careerPositionLocation,
      path: '/senior-ux-designer',
    },
    {
      title: careerPositionQA,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionQa,
      path: '/quality-assurance',
    },
    {
      title: careerPositionProjectManagement,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionProjectManagement,
      path: '/project-management',
    },
    {
      title: careerPositionProjectManager,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionProjectManager,
      path: '/project-manager',
    },
    {
      title: careerPositionProjectSeniorCostumerSupport,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionCostumerSupport,
      path: '/senior-customer-support',
    },
    {
      title: careerPositionSeniorContentManager,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionContentManager,
      path: '/senior-content-manager',
    },
    {
      title: careerPositionProjectSocialMediaMarketingSpecialist,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionSocialMediaMarketingSpecialist,
      path: '/social-media-marketing-specialist',
    },
    {
      title: careerPositionMarketingManager,
      text: careerPositionText,
      description: careerDescriptionMarketingManager,
      location: careerPositionLocation,
      path: '/marketing-manager',
    },
    {
      title: careerPositionProjectSeniorTechnicalWriter,
      text: careerPositionText,
      location: careerPositionLocation,
      description: careerDescriptionTechnicalWriter,
      path: '/senior-technical-write',
    },
  ]);

  return (
    <div className='career-position-section'>
      <Swiper
        navigation={false}
        pagination={{
          type: 'progressbar',
        }}
        modules={[Pagination, Navigation]}
        className='career-position-section-swiper'
        loop
        slidesPerView={1}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1350: {
            slidesPerView: 4,
          },
        }}
      >
        {careerPosition.map((item, i) => {
          return (
            <SwiperSlide key={item.title}>
              <div className='career-position-section-swiper-item'>
                <h3
                  className='career-position-section-swiper-item-title'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <p
                  className='career-position-section-swiper-item-description'
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
                <Link
                  to={item.path}
                  className='career-position-section-swiper-item-button career-position-section-swiper-item-button-display '
                >
                  Read More
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
export default CareerPosition;
