import React, { useState } from 'react';
import CareerPosition from '../component/OurStaff/careerPositions';
import FooterComponet from '../component/footerComponent';
import { graphql } from 'gatsby';

import Layout from '../component/layout';
import OurStaffHomeComponent from '../component/SuccessStories/ourStaffHomeComponent';
import ApplyNowModal from '../component/applyNowModal';
const Career = props => {
  const [openModalApply, setOpenModalApply] = useState(false);

  const {
    data: {
      wpPage: {
        seo,
        careerPositionSection: {
          careerPositionBackend,
          careerPositionContentManager,
          careerPositionDescription,
          careerPositionDesigner,
          careerPositionDevops,
          careerPositionFornend,
          careerPositionLocation,
          careerPositionMarketingManager,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionQa,
          careerPositionSocialMediaMarketing,
          careerPositionSupport,
          careerPositionTechnicalWriter,
          careerPositionText,
          careerPositionTitle,
        },
        careerDescriptionSection: {
          careerDescriptionFrontend,
          careerDescriptionBackend,
          careerDescriptionContentManager,
          careerDescriptionCostumerSupport,
          careerDescriptionDevops,
          careerDescriptionMarketingManager,
          careerDescriptionProjectManagement,
          careerDescriptionProjectManager,
          careerDescriptionQa,
          careerDescriptionSocialMediaMarketingSpecialist,
          careerDescriptionTechnicalWriter,
          careerDescriptionTitle,
          careerDescriptionUxdesigner,
        },
        homePageHeaderSection: {
          homeHeaderTitle,
          homeHeaderDescription,
          homeHeaderImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourStaffHomeImage },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={{title:"Build your career at Motomtech - Exciting Career Opportunities", metaDesc:'Motomtech, IT services, software development, offshore development, technology solutions, IT outsourcing'}}>
      <div className='ourStaff-home-section'>
        <OurStaffHomeComponent
          ourStaffHomeTitle={homeHeaderTitle}
          ourStaffHomeDescription={homeHeaderDescription}
          ourStaffHomeImage={ourStaffHomeImage}
        />
        <CareerPosition
          careerPositionTitle={careerPositionTitle}
          careerPositionSubtitle={careerPositionDescription}
          careerPositionFrontEnd={careerPositionFornend}
          careerPositionText={careerPositionText}
          careerPositionLocation={careerPositionLocation}
          careerPositionBackEnd={careerPositionBackend}
          careerPositionDevOps={careerPositionDevops}
          careerPositionUXDesigner={careerPositionDesigner}
          careerPositionQA={careerPositionQa}
          careerPositionProjectManagement={careerPositionProjectManagement}
          careerPositionProjectManager={careerPositionProjectManager}
          careerPositionProjectSeniorCostumerSupport={careerPositionSupport}
          careerPositionSeniorContentManager={careerPositionMarketingManager}
          careerPositionProjectSocialMediaMarketingSpecialist={
            careerPositionSocialMediaMarketing
          }
          careerPositionMarketingManager={careerPositionContentManager}
          careerPositionProjectSeniorTechnicalWriter={
            careerPositionTechnicalWriter
          }
          careerDescriptionFrontend={careerDescriptionFrontend}
          careerDescriptionBackend={careerDescriptionBackend}
          careerDescriptionContentManager={careerDescriptionContentManager}
          careerDescriptionCostumerSupport={careerDescriptionCostumerSupport}
          careerDescriptionDevops={careerDescriptionDevops}
          careerDescriptionMarketingManager={careerDescriptionMarketingManager}
          careerDescriptionProjectManagement={
            careerDescriptionProjectManagement
          }
          careerDescriptionProjectManager={careerDescriptionProjectManager}
          careerDescriptionQa={careerDescriptionQa}
          careerDescriptionSocialMediaMarketingSpecialist={
            careerDescriptionSocialMediaMarketingSpecialist
          }
          careerDescriptionTechnicalWriter={careerDescriptionTechnicalWriter}
          careerDescriptionUxdesigner={careerDescriptionUxdesigner}
        />
        <div className='ourStaff-home-section-hire'>
          <h3
            className='ourStaff-home-section-hire-text'
            dangerouslySetInnerHTML={{ __html: careerDescriptionTitle }}
          />
          <button
            // to={'/apply'}
            onClick={() => setOpenModalApply(true)}
            className='career-position-section-swiper-item-button '
          >
            Apply Now
          </button>
        </div>
        <ApplyNowModal
          isOpen={openModalApply}
          onClose={() => setOpenModalApply(false)}
        />
      </div>
      <FooterComponet />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "career-page" }) {
      seo {
        metaDesc
        title
      }
      careerPositionSection {
        careerPositionBackend
        careerPositionContentManager
        careerPositionCostumerSupport
        careerPositionDescription
        careerPositionDesigner
        careerPositionDevops
        careerPositionFornend
        careerPositionLocation
        careerPositionMarketingManager
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionQa
        careerPositionSocialMediaMarketing
        careerPositionSupport
        careerPositionTechnicalWriter
        careerPositionText
        careerPositionTitle
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      careerDescriptionSection {
        careerDescriptionFrontend
        careerDescriptionBackend
        careerDescriptionContentManager
        careerDescriptionCostumerSupport
        careerDescriptionDevops
        careerDescriptionMarketingManager
        careerDescriptionProjectManagement
        careerDescriptionProjectManager
        careerDescriptionQa
        careerDescriptionSocialMediaMarketingSpecialist
        careerDescriptionTechnicalWriter
        careerDescriptionTitle
        careerDescriptionUxdesigner
      }
    }
  }
`;
export default Career;
